.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-error {
  color: #9c2727;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiMenuItem-root, .MuiSelect-select, .MuiFormLabel-root, .MuiInput-input {
  font-size: 0.75rem !important;
}

#processPopupDialog.evsHidden .MuiDataGrid-columnHeaders,
  #processPopupDialog .evsHidden .MuiDataGrid-columnHeaders {
  display:none !important;
}

.MuiDataGrid-columnHeader .MuiFormLabel-root {
  margin-top:-13px !important;
  padding-top:0 !important;
}

.MuiDataGrid-columnHeader .MuiInputLabel-shrink {
  margin-top:-4px !important;
  padding-top:0 !important;  
  display:none !important; 
  color:#000000 !important;  
}

.loginForm .MuiInputLabel-shrink {
  padding-top:6px !important;  
  color:#666666 !important;  
}
.loginForm .MuiOutlinedInput-notchedOutline {
  font-size: 0.75rem !important;
  border-radius: 12px;
  color:#000000 !important; 
  border:0;  
}
.loginForm input {
  color:#000000 !important;  
}
.loginForm .MuiInputBase-input {
  font-size: 1em !important;
  padding:12px !important;
  margin-top:0 !important;
  background-color: #ffffff;
  border-radius: 12px;
  color:#000000 !important; 
}
.loginForm {
  font-size: 1.2em !important;
}
.loginForm .MuiInputLabel-shrink {  
  margin-top:4px !important;
}
#loginButton:hover {
  background-color:#9c2727;
}

.MuiDataGrid-headerFilterRow .MuiFormControl-root, 
  .MuiDataGrid-headerFilterRow .MuiInput-underline, 
  .MuiDataGrid-headerFilterRow .MuiSelect-select {
  margin-top:0 !important;
  padding-top:0 !important;  
  margin-bottom:3px !important;
  padding-bottom:0 !important;
}

.MuiDataGrid-headerFilterRow .MuiSelect-icon, .MuiDataGrid-headerFilterRow .MuiSelect-iconOpen {
  padding:0 0px 0px 0px !important;    
  margin:0 !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #dfe3e5 !important;
}

.MuiListItemText-primary {
  font-size:.9rem !important;
}

.disabledGridCell {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.disabledGridIcon {
  opacity: 0.8;
  filter: alpha(opacity=80);
  filter: sepia(30%) saturate(0%); /* msie */   
}