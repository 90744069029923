body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submenuTitle {
  color: #B90000;
  /* desktop/h6 */  
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
}

.menuSelector {
  color: #717171 !important;
}
.menuSelector .MuiOutlinedInput-notchedOutline {   
  border: 0 !important;  
}

.submenuItemSelected {  
  background-color: #ffd8d8 !important;
}

.menuListItem {
  opacity:1;
  font-size:.8rem !important;
}
.menuListItem .menuListItemIcon {
  opacity:.6;
  font-size:.8rem !important;
}
.menuListItem .menuListItemText {
  color:#fff !important;
  opacity:.8 !important;
}
.menuListItem:hover .menuListItemIcon {
  opacity:1 !important;
}

.menuListItem:hover .menuListItemText {
  color:#fff !important;
  opacity:1 !important;
}
.menuListItem:hover {  
  opacity:1 !important;
}

.menuListItemSelected {  
  background-color: #900000 !important;
  font-size:.8rem !important;
}

a {
  text-decoration:none;
  color:unset;
}

.cellUnpaidInvoice {
  background-color:hwb(0 68% 5%);
}
.cellPaidInvoice {
  background-color:rgb(183, 241, 183);
}
.cellActionDue {
  color:rgb(192, 34, 34);
}
